import * as React from 'react'

import SEO from '../components/seo/seo'
import NoPropertiesYet from '../components/activeJobs/NoPropertiesYet'
import StaticDrawerWrapper from '../components/ui/sidebar/staticDrawerWrapper'
import DisastrousErrorHandler from '../components/ui/DisastrousErrorHandler'
import DemoSidebar from '../components/demo/DemoSidebar'

const DemoPage = () => (
	<DisastrousErrorHandler>
		<StaticDrawerWrapper hideBottom sideArea={() => <DemoSidebar />}>
			<SEO title="Fairmarket Demo" keywords={['Fairmarket', 'Demo']} />
			<NoPropertiesYet />
		</StaticDrawerWrapper>
	</DisastrousErrorHandler>
)

export default DemoPage
