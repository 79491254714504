import React from 'react'
import styled from 'styled-components'
import {Typography} from '@material-ui/core'
import DrawerTopArea from '../ui/sidebar/DrawerTopArea'

interface Props {}

const DemoSidebar = ({}: Props) => {
  return (
    <DrawerTopArea>
      {/* <HelperText>Welcome to the Fairmarket Demo </HelperText> */}
    </DrawerTopArea>
  )
}

export default React.memo(DemoSidebar)

const HelperText = styled(Typography)`
  color: white;
  margin: 8px;
  text-align: center;
`